/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from '@allconnect/store';
import TopLayout from './src/TopLayout';

require('typeface-roboto');
require('typeface-roboto-slab');

const store = createStore();
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <TopLayout>{element}</TopLayout>
    </Provider>
  );
};

if (typeof window !== 'undefined')
  if (window.Cypress) {
    window.store = store;
  }
