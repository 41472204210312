import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { lightTheme, darkTheme } from './theme';

import { onLoadStart } from '@allconnect/store/lib/user';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectTheme,
  selectLang,
} from '@allconnect/store/lib/appStatus/appStatus.selectors';

const TopLayout = ({ children, onLoad, currentTheme, lang }) => {
  useEffect(() => {
    onLoad();
  }, []);

  const theme = currentTheme === 'light' ? lightTheme : darkTheme;

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {children}
      </ThemeProvider>
    </React.Fragment>
  );
};

TopLayout.propTypes = {
  children: PropTypes.node,
};

const mapStateToProps = createStructuredSelector({
  currentTheme: selectTheme,
  lang: selectLang,
});

const mapDispatchToProps = dispatch => ({
  onLoad: () => dispatch(onLoadStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopLayout);
