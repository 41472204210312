// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-on-tsx": () => import("./../../../src/pages/on.tsx" /* webpackChunkName: "component---src-pages-on-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-servers-tsx": () => import("./../../../src/pages/servers.tsx" /* webpackChunkName: "component---src-pages-servers-tsx" */),
  "component---src-pages-signout-tsx": () => import("./../../../src/pages/signout.tsx" /* webpackChunkName: "component---src-pages-signout-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-upgrade-tsx": () => import("./../../../src/pages/upgrade.tsx" /* webpackChunkName: "component---src-pages-upgrade-tsx" */),
  "component---src-templates-all-category-tsx": () => import("./../../../src/templates/AllCategory.tsx" /* webpackChunkName: "component---src-templates-all-category-tsx" */),
  "component---src-templates-all-tag-tsx": () => import("./../../../src/templates/AllTag.tsx" /* webpackChunkName: "component---src-templates-all-tag-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/Category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-help-tsx": () => import("./../../../src/templates/Help.tsx" /* webpackChunkName: "component---src-templates-help-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/Post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/Tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

